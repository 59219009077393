import { render, staticRenderFns } from "./ReceiveMessage.vue?vue&type=template&id=6f66904a&scoped=true&"
import script from "./ReceiveMessage.vue?vue&type=script&lang=js&"
export * from "./ReceiveMessage.vue?vue&type=script&lang=js&"
import style0 from "./ReceiveMessage.vue?vue&type=style&index=0&id=6f66904a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f66904a",
  null
  
)

export default component.exports